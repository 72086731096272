export const driverMixin = {

  data: function () {

    var checkIPAddress = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('drivers.pls-type-ip-address')));
      }

      if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(value)) {
        callback();
      } else {
        callback(new Error(this.$t('drivers.pls-type-valid-ip-address')));
      }
    };
    var checkDriverType = (rule, value, callback) => {
      if (!value) {
        callback(new Error(this.$t('drivers.pls-select-driver-type')));
      } else {
        callback();
      }
    };

    return {
      rules: {
        driverType: [
          { validator: checkDriverType, trigger: 'blur' }
        ],
        ipAddress: [
          { validator: checkIPAddress, trigger: 'blur' }
        ]
      },
    }
  }

}