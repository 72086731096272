<template>
  <div>
    <app-navigation
      :title="$t('drivers.driver-add')"
      :backButtonText="$t('navigation.back')"
    ></app-navigation>

    <el-form
      :model="driverData"
      :rules="rules"
      ref="driverForm"
      class="driver-container"
      label-position="left"
      label-width="120px"
    >
      <h5>{{ $t("drivers.driver-add") }}</h5>

      <el-form-item prop="driverType" :label="$t('drivers.driver-type')">
        <el-select
          v-model="driverData.driverType"
          :placeholder="$t('drivers.select-driver-type')"
        >
          <el-option
            v-for="type in driverList"
            :label="type.title"
            :value="type.value"
            :key="type.value"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item
        prop="connectionType"
        label="Connection Type"
        v-if="showConnectionType(driverData.driverType)"
      >
        <el-select
          v-model="driverData.connectionType"
          placeholder="Connection Type"
        >
          <el-option
            v-for="type in modbusConnectionTypes"
            :label="type.title"
            :value="type.value"
            :key="type.value"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item
        :label="$t('drivers.driver-mode')"
        v-if="driverData.driverType == DRIVER_TYPE_COREOS_PANEL"
      >
        <el-select v-model="driverData.driverMode">
          <el-option
            v-for="mode in driverModes"
            :label="$t('drivers.' + mode.title)"
            :value="mode.value"
            :key="mode.value"
          />
        </el-select>
      </el-form-item>

      <el-form-item
        prop="ipAddress"
        :label="$t('drivers.ip-address')"
        v-if="showIpAddress(driverData.driverType)"
      >
        <el-input
          type="text"
          v-model="driverData.ipAddress"
          auto-complete="on"
          :placeholder="$t('drivers.type-ip-address')"
          v-mask="ipMask"
        >
          <template v-if="getPort(driverData.driverType) > 0" slot="append"
            >:{{ getPort(driverData.driverType) }}</template
          >
        </el-input>
      </el-form-item>

      <table
        v-if="
          driverData.driverType == DRIVER_TYPE_MODBUS &&
          showIpInputModbus(driverData.connectionType)
        "
      >
        <tr>
          <td tyle="width:80%">
            <el-form-item prop="ipAddress" :label="$t('drivers.ip-address')">
              <el-input
                type="text"
                v-model="driverData.ipAddress"
                auto-complete="on"
                :placeholder="$t('drivers.type-ip-address')"
                v-mask="ipMask"
              />
            </el-form-item>
          </td>
          <td style="width: 20%">
            <el-form-item
              label-width="0"
              prop="port"
              :rules="[{ required: true, message: $t('drivers.rule-port') }]"
            >
              <el-input
                min="0"
                max="65535"
                type="number"
                @change="onPortChanged"
                v-model="driverData.port"
                :placeholder="$t('drivers.port')"
              />
            </el-form-item>
          </td>
        </tr>
      </table>

      <el-form-item
        label="Connector"
        v-if="showConnector(driverData.driverType, driverData.connectionType)"
      >
        <el-select v-model="driverData.connector" placeholder="Connector">
          <el-option
            v-for="connector in modbusConnectors"
            :label="connector.title"
            :value="connector.value"
            :key="connector.value"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item
        label="BaudRate"
        v-if="showBaudrate(driverData.driverType, driverData.connectionType)"
      >
        <el-select v-model="driverData.baudRate" placeholder="BaudRate">
          <el-option
            v-for="baudRate in baudrates"
            :label="baudRate"
            :value="baudRate"
            :key="baudRate"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item style="text-align: left">
        <el-button
          type="primary"
          @click.native.prevent="submitDriverForm"
          :loading="loading"
          icon="el-icon-plus"
          >{{ $t("drivers.add") }}</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {
  DRIVER_MODE_DRIVER,
  driverModes,
  CONNECTION_TCP,
  RS485,
  showConnector,
  showBaudrate,
  connectors,
  baudrates,
  connectionTypes,
  showConnectionType,
  showIpInputModbus,
  getPort,
  driverTypes,
  showIpAddress,
  DRIVER_TYPE_COREOS_PANEL,
  DRIVER_TYPE_CORECENTER,
  DRIVER_TYPE_GPIO,
  DRIVER_TYPE_MODBUS,
  DRIVER_TYPE_LOGO,
  DRIVER_TYPE_KNXIP,
  DRIVER_TYPE_KNXTP,
  CONNECTION_RTU,
  RS232,
} from "@/utils/driver-utils";

import Navigation from "@/components/Navigation";
import { driverMixin } from "./driverMixin";
import {
  DRIVER_TYPE_COOLMASTER,
  DRIVER_TYPE_HUE,
  DRIVER_TYPE_RS485,
} from "../../utils/driver-utils";

export default {
  name: "NewDriver",
  components: {
    "app-navigation": Navigation,
  },

  mixins: [driverMixin],

  data: function() {
    return {
      driverData: {
        driverType: "",
        ipAddress: "",
        port: 0,
        connectionType: CONNECTION_TCP,
        connector: RS485,
        baudRate: 9600,
        driverMode: DRIVER_MODE_DRIVER,
      },
      connectors,
      baudrates,
      driverModes,
      DRIVER_TYPE_MODBUS,
      DRIVER_TYPE_COREOS_PANEL,
      driverTypes,
      connectionTypes,
      loading: false,

      ipMask: {
        mask: "9{1,3}.9{1,3}.9{1,3}.9{1,3}",
      },
    };
  },

  computed: {
    driverList: function() {
      let drivers = this.driverTypes;

      let productCode = this.$store.getters.productCode;
      let brand = this.$store.getters.brand;
      console.log("productCode", productCode);

      if (!productCode.includes("KNX")) {
        drivers = drivers.filter((driver) => driver.value != DRIVER_TYPE_KNXTP);
      } else {
        const knxtpDriver = this.$store.getters.drivers.find(
          (driver) => driver.driverType == DRIVER_TYPE_KNXTP
        );
        if (knxtpDriver)
          drivers = drivers.filter(
            (driver) => driver.value != DRIVER_TYPE_KNXTP
          );
      }

      const gpioDriver = this.$store.getters.drivers.find(
        (driver) => driver.driverType == DRIVER_TYPE_GPIO
      );
      if (gpioDriver)
        drivers = drivers.filter((driver) => driver.value != DRIVER_TYPE_GPIO);

      if (brand === "EAE") {
        drivers = drivers.filter(
          (driver) =>
            driver.value != DRIVER_TYPE_COREOS_PANEL &&
            driver.value != DRIVER_TYPE_CORECENTER &&
            driver.value != DRIVER_TYPE_MODBUS &&
            driver.value != DRIVER_TYPE_LOGO &&
            driver.value != DRIVER_TYPE_COOLMASTER &&
            driver.value != DRIVER_TYPE_RS485 &&
            driver.value != DRIVER_TYPE_HUE
        );
      } else {
        // const modbusDriver = this.$store.getters.drivers.find(
        //   (driver) => driver.driverType == DRIVER_TYPE_MODBUS
        // );
        // if (modbusDriver)
        //   drivers = drivers.filter(
        //     (driver) => driver.value != DRIVER_TYPE_MODBUS
        //   );

        const corecenterDriver = this.$store.getters.drivers.find(
          (driver) => driver.driverType == DRIVER_TYPE_CORECENTER
        );
        if (corecenterDriver)
          drivers = drivers.filter(
            (driver) => driver.value != DRIVER_TYPE_CORECENTER
          );
      }

      return drivers;
    },

    modbusConnectionTypes: function() {
      let existsRS232 = this.$store.getters.existsRS232 || true;
      let existsRS485 = this.$store.getters.existsRS485 || true;

      let rtuDrivers = this.$store.getters.drivers
        .filter((driver) => driver.driverType == DRIVER_TYPE_MODBUS)
        .filter((driver) => {
          let extraData = JSON.parse(driver.extraData);
          return extraData.connectionType == CONNECTION_RTU;
        });

      console.log("rtu", rtuDrivers);

      if ((!existsRS232 && !existsRS485) || rtuDrivers.length == 2)
        return this.connectionTypes.filter(
          (type) => type.value != CONNECTION_RTU
        );
      else return this.connectionTypes;
    },

    modbusConnectors: function() {
      let existsRS232 = this.$store.getters.existsRS232 || true;
      let existsRS485 = this.$store.getters.existsRS485 || true;

      let rtuDrivers = this.$store.getters.drivers
        .filter((driver) => driver.driverType == DRIVER_TYPE_MODBUS)
        .filter((driver) => {
          let data = JSON.parse(driver.extraData);
          return data.connectionType == CONNECTION_RTU;
        });

      if (existsRS232) {
        existsRS232 =
          rtuDrivers.filter((driver) => {
            let data = JSON.parse(driver.extraData);
            return data.connector == RS232;
          }).length == 0;
      }

      if (existsRS485) {
        existsRS485 =
          rtuDrivers.filter((driver) => {
            let data = JSON.parse(driver.extraData);
            return data.connector == RS485;
          }).length == 0;
      }

      console.log("connectors", existsRS232, existsRS485, rtuDrivers);

      if (!existsRS232)
        return this.connectors.filter((conn) => conn.value != RS232);
      else if (!existsRS485)
        return this.connectors.filter((conn) => conn.value != RS485);
      else return this.connectors;
    },
  },

  methods: {
    showBaudrate,
    showConnector,
    showIpInputModbus,
    showConnectionType,
    showIpAddress,
    getPort,

    onPortChanged(value) {
      if (value < 0) {
        this.driverData.port = 0;
      } else if (value > 65535) {
        this.driverData.port = 65535;
      }
    },

    submitDriverForm(event) {
      this.$refs.driverForm.validate((valid) => {
        if (valid) {
          this.loading = true;

          if (this.driverData.driverType != DRIVER_TYPE_MODBUS)
            this.driverData.port = this.getPort(this.driverData.driverType);
          if (this.driverData.driverType == DRIVER_TYPE_MODBUS) {
            this.driverData.extraData = {};
            this.driverData.extraData = JSON.stringify({
              connectionType: this.driverData.connectionType,
              connector: this.driverData.connector,
              baudRate: this.driverData.baudRate,
            });
          } else if (this.driverData.driverType == DRIVER_TYPE_COREOS_PANEL) {
            this.driverData.extraData = {};
            this.driverData.extraData = JSON.stringify({
              driverMode: this.driverData.driverMode,
            });
          }

          //this.driverData.ipAddress = this.driverData.ipAddress.replace(/_/g, '')

          this.$store
            .dispatch("addDriver", this.driverData)
            .then((response) => {
              this.loading = false;
              this.$message({
                message: this.$t(response.message),
                type: "success",
              });

              this.$router.go(-1);
            })
            .catch((reason) => {
              this.loading = false;
              this.$message({
                message: this.$t(reason),
                type: "error",
              });
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.driver-container {
  margin-left: 20px;
  background: #fff;
  border-radius: 10px;
  margin-right: 20px;
  padding: 15px 25px 15px 25px;
  border: 1px solid #eaeaea;
  width: 480px;
}
</style>
